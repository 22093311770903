import type { SelfDescribingJson } from '@snowplow/tracker-core';

export type ContextGetter<A extends { schemaVersion?: string }> = (
    arg: A,
) => SelfDescribingJson;

export enum FlowEnum {
    seller = 'Seller',
    buyer = 'Buyer',
    career = 'Career',
    rent = 'Renter',
    other = 'Other',
}

export type FlowType =
    | FlowEnum.seller
    | FlowEnum.buyer
    | FlowEnum.career
    | FlowEnum.rent
    | FlowEnum.other
    | string;

export enum PropertyTypesEnum {
    apartment = 'apartment',
    house = 'house',
    office = 'office',
    hotel = 'hotel',
    industrial = 'industrial',
    investment = 'investment',
    plot = 'plot',
    yacht = 'yacht',
    plane = 'plane',
    other = 'Other',
}

export type PropertyTypesType =
    | PropertyTypesEnum.apartment
    | PropertyTypesEnum.house
    | PropertyTypesEnum.office
    | PropertyTypesEnum.hotel
    | PropertyTypesEnum.industrial
    | PropertyTypesEnum.investment
    | PropertyTypesEnum.plot
    | PropertyTypesEnum.yacht
    | PropertyTypesEnum.plane
    | PropertyTypesEnum.other
    | string;

export enum SalutationEnum {
    male = 'male',
    female = 'female',
    other = 'other',
    none = '-',
}

export type SalutationType =
    | SalutationEnum.male
    | SalutationEnum.female
    | SalutationEnum.other
    | SalutationEnum.none;

export enum MeasurementSystemEnum {
    metric = 'metric',
    imperial = 'imperial',
    us = 'us',
}
export type MeasurementSystemType =
    | MeasurementSystemEnum.metric
    | MeasurementSystemEnum.us
    | MeasurementSystemEnum.imperial;

export enum BusinessDivisionEnum {
    residential = 'residential',
    commercial = 'commercial',
    yachting = 'yachting',
    other = 'other',
}
export type BusinessDivisionType =
    | BusinessDivisionEnum.residential
    | BusinessDivisionEnum.commercial
    | BusinessDivisionEnum.yachting
    | BusinessDivisionEnum.other;
