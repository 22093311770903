import type { SelfDescribingJson } from '@snowplow/tracker-core';

export type LeadEvent = {
    source: string;
    contexts?: SelfDescribingJson[];
    schemaVersion?: string;
};

export type NavigationEvent = {
    screenName: string;
    language?: string;
    countryCode?: string | undefined;
    contexts?: SelfDescribingJson[];
    schemaVersion?: string;
};

export type CustomEvent = {
    schema: string;
    data: Record<string, unknown>;
    contexts?: SelfDescribingJson[];
};

export type WatchlistEvent = {
    action: string;
    schemaVersion?: string;
    contexts?: SelfDescribingJson[];
};

export enum WatchListEnum {
    add = 'add',
    remove = 'remove',
}

export type WatchListType = WatchListEnum.add | WatchListEnum.remove;

export type SelfDescribingEvent = (arg: CustomEvent) => void;

export enum UserActionEnum {
    input = 'input',
    click = 'click',
    focus = 'focus',
    scroll = 'scroll'
}

export type UserActionType =
    UserActionEnum.input
    | UserActionEnum.click
    | UserActionEnum.focus
    | UserActionEnum.scroll;

export type UserInteractionEvent = {
    action: UserActionType;
    elementId: string;
    contexts?: SelfDescribingJson[];
    schemaVersion?: string;
};

export type PropertyImpressionEvent = {
    schemaVersion?: string;
    contexts?: SelfDescribingJson[];
};

export type SubscribeEvent = {
    schemaVersion?: string;
    contexts?: SelfDescribingJson[];
};

export type UnsubscribeEvent = {
    schemaVersion?: string;
    contexts?: SelfDescribingJson[];
};