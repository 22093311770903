import { trackSelfDescribingEvent as trackSnowplowSelfDescribingEvent } from '@snowplow/browser-tracker';
import type {
    LeadEvent,
    NavigationEvent,
    SelfDescribingEvent,
    WatchlistEvent,
    UserInteractionEvent,
    PropertyImpressionEvent,
    SubscribeEvent,
    UnsubscribeEvent
} from './trackerTypes';
import { getSnowplowAppId } from './init';

export const trackLeadEvent = ({
    source,
    contexts,
    schemaVersion = '1-0-0',
}: LeadEvent) => {
    trackSnowplowSelfDescribingEvent({
        event: {
            schema: `iglu:com.engelvoelkers/lead_created/jsonschema/${schemaVersion}`,
            data: {
                source: `${getSnowplowAppId()}_${source}`,
            },
        },
        context: contexts,
    });
};

export const trackNavigationEvent = ({
    screenName,
    language,
    countryCode,
    contexts,
    schemaVersion = '1-0-0',
}: NavigationEvent) => {
    trackSnowplowSelfDescribingEvent({
        event: {
            schema: `iglu:com.engelvoelkers/navigation_event/jsonschema/${schemaVersion}`,
            data: {
                screenName,
                language,
                countryCode,
            },
        },
        context: contexts,
    });
};

export const trackWatchlistEvent = ({
    action,
    contexts,
    schemaVersion = '1-0-0',
}: WatchlistEvent) => {
    trackSnowplowSelfDescribingEvent({
        event: {
            schema: `iglu:com.engelvoelkers/watchlist_event/jsonschema/${schemaVersion}`,
            data: {
                action,
            },
        },
        context: contexts,
    });
};

export const trackSelfDescribingEvent: SelfDescribingEvent = ({
    schema,
    data,
    contexts,
}) => {
    const eventData = {
        event: {
            schema,
            data,
        },
        context: contexts,
    };

    trackSnowplowSelfDescribingEvent(eventData);
};

export const trackUserInteractionEvent = ({
    contexts,
    action,
    elementId,
    schemaVersion = '1-0-2',
}: UserInteractionEvent) => {
    trackSnowplowSelfDescribingEvent({
        event: {
            schema: `iglu:com.engelvoelkers/user_interaction_event/jsonschema/${schemaVersion}`,
            data: {
                action,
                elementId,
            },
        },
        context: contexts,
    });
};

export const trackPropertyImpressionEvent = ({
    contexts,
    schemaVersion = '1-0-0',
}: PropertyImpressionEvent) => {
    trackSnowplowSelfDescribingEvent({
        event: {
            schema: `iglu:com.engelvoelkers/property_impression_event/jsonschema/${schemaVersion}`,
            data: {},
        },
        context: contexts,
    });
};


export const trackSubscribeEvent = ({
    contexts,
    schemaVersion = '1-0-0',
}: SubscribeEvent) => {
    trackSnowplowSelfDescribingEvent({
        event: {
            schema: `iglu:com.engelvoelkers/subscribe_event/jsonschema/${schemaVersion}`,
            data: {},
        },
        context: contexts,
    });
};

export const trackUnsubscribeEvent = ({
    contexts,
    schemaVersion = '1-0-0',
}: UnsubscribeEvent) => {
    trackSnowplowSelfDescribingEvent({
        event: {
            schema: `iglu:com.engelvoelkers/unsubscribe_event/jsonschema/${schemaVersion}`,
            data: {},
        },
        context: contexts,
    });
};